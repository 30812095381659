import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import { useSelector } from 'react-redux';
import {
    clearParameters,
    removeParameter,
    setParameters,
} from '../../redux/global/actions';
import {
    useNavigate
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import MultiValueInput from '../../inputs/multiValueInput';
import DateInput from '../../inputs/dateInput';
import { Fab, Grid, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect } from 'react';
import './DataQualitySapOrders.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchDateInput from '../../inputs/searchDateInput';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import {
    exportDataQualitySapOrdersToCSV,
    filterDataQualitySapOrders,
    getDataQualitySapOrders, getDataQualitySapOrdersBulk,
    sortDataQualitySapOrders,
} from "../../redux/dynamo_db/actions";

export default function DataQualitySapOrders() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const queryParameters = useSelector(state => state?.parameters?.dataQualitySapOrders);

    const [currentPartNumber, setCurrentPartNumber] = React.useState();
    const [currentSoldTo, setCurrentSoldTo] = React.useState();
    const [currentOrderId, setCurrentOrderId] = React.useState();
    const [currentStatus, setCurrentStatus] = React.useState();

    const [filterPartNumber, setFilterPartNumber] = React.useState([]);
    const [filterSoldTos, setFilterSoldTos] = React.useState([]);
    const [filterStatus, setFilterStatus] = React.useState([]);
    const [filterOrderIds, setFilterOrderIds] = React.useState([]);
    const [filterOrderLines, setFilterOrderLines] = React.useState([]);
    const [filterCreationDateStart, setFilterCreationDateStart] = React.useState();
    const [filterCreationDateEnd, setFilterCreationDateEnd] = React.useState();
    const [openModal, setOpenModal] = React.useState(false);

    const handleExecuteSearch = () => {
        let queryObject = {}
        if (queryParameters.partNumber) {
            queryObject.part_number = queryParameters.partNumber;
        };
        if (queryParameters.soldTos) {
            queryObject.sold_to = queryParameters.soldTos;
        };
        if (queryParameters.orderIds) {
            queryObject.order_id = queryParameters.orderIds;
        };
        if (queryParameters.status) {
            queryObject.status = queryParameters.status;
        };
        if (queryParameters.creationDateStart && queryParameters.creationDateEnd) {
            queryObject.creation_date_from = queryParameters.creationDateStart;
            queryObject.creation_date_to = queryParameters.creationDateEnd;
        }
        else{
            setOpenModal(true);
            return
        };
        dispatch(getDataQualitySapOrders(queryObject));
    };

    const handleExecuteFilter = () => {
        let filterObject = {};
        if (filterPartNumber?.length > 0) {
            filterObject.partNumbers = [filterPartNumber];
        };
        if (filterSoldTos?.length > 0) {
            filterObject.soldTos = [filterSoldTos];
        };
        if (filterStatus?.length > 0) {
            filterObject.status = [filterStatus];
        };
        if (filterOrderLines?.length > 0) {
            filterObject.orderLine = [filterOrderLines];
        };
        if (filterOrderIds?.length > 0) {
            filterObject.orderId = [filterOrderIds];
        };
        if (filterCreationDateStart) {
            filterObject.filterCreationDateStart = filterCreationDateStart;
        };
        if (filterCreationDateEnd) {
            filterObject.filterCreationDateEnd = filterCreationDateEnd;
        };
        dispatch(filterDataQualitySapOrders(filterObject));
    };

    const handleExecuteBulkSearch = () => {
        let queryObject = {}
        if (queryParameters.partNumber) {
            queryObject.part_number = queryParameters.partNumber;
        };
        if (queryParameters.soldTos) {
            queryObject.sold_to = queryParameters.soldTos;
        };
        if (queryParameters.orderIds) {
            queryObject.order = queryParameters.orderIds;
        };
        if (queryParameters.status) {
            queryObject.type = queryParameters.status;
        };
        if (queryParameters.creationDateStart && queryParameters.creationDateEnd) {
            queryObject.creation_date_from = queryParameters.creationDateStart;
            queryObject.creation_date_to = queryParameters.creationDateEnd;
        }
        else{
            setOpenModal(true);
            return
        };
        queryObject.is_bulk = true;
        dispatch(getDataQualitySapOrdersBulk(queryObject));
    };

    useEffect(() => {
        handleExecuteFilter()
    }, [
        filterPartNumber,
        filterStatus,
        filterSoldTos,
        filterOrderIds,
        filterOrderLines,
        filterCreationDateStart,
        filterCreationDateEnd
    ]);

    const handleDeletePartNumber = (chipToDelete) => () => {
        dispatch(removeParameter({ dataQualitySapOrders: { partNumber: [chipToDelete] } }));
    };
    const handleClearPartNumber = () => {
        dispatch(clearParameters({ dataQualitySapOrders: 'partNumber' }));
    };
    const handleDeleteSoldTo = (chipToDelete) => () => {
        dispatch(removeParameter({ dataQualitySapOrders: { soldTos: [chipToDelete] } }));
    };
    const handleClearSoldTos = () => {
        dispatch(clearParameters({ dataQualitySapOrders: 'soldTos' }))
    };
    const handleDeleteOrderId = (chipToDelete) => () => {
        dispatch(removeParameter({ dataQualitySapOrders: { orderIds: [chipToDelete] } }));
    };
    const handleClearOrderIds = () => {
        dispatch(clearParameters({ dataQualitySapOrders: 'orderIds' }))
    };
    const handleDeleteStatus = (chipToDelete) => () => {
        dispatch(removeParameter({ dataQualitySapOrders: { status: [chipToDelete] } }));
    };
    const handleClearStatus = () => {
        dispatch(clearParameters({ dataQualitySapOrders: 'status' }))
    };

    const handleExportToCSV = (dataqualitysaporders) => {
        dispatch(exportDataQualitySapOrdersToCSV(dataqualitysaporders))
    };

    const dataqualitysaporders = useSelector(state => {
        if (state.filteredDataQualitySapOrders) {
            return state.filteredDataQualitySapOrders.map((data_quality_sap_orders) => {
                return {
                    data_quality_sap_orders_identifier: data_quality_sap_orders?.SK,
                    creation_date: data_quality_sap_orders?.creation_date,
                    part_number: data_quality_sap_orders?.part_number,
                    sold_to: data_quality_sap_orders?.sold_to,
                    status: data_quality_sap_orders?.status,
                    order_id: data_quality_sap_orders?.order_id,
                    order_line: data_quality_sap_orders?.order_line,
                    quantity: data_quality_sap_orders?.quantity,
                    real_quantity: data_quality_sap_orders?.real_quantity,
                    missing_quantity: data_quality_sap_orders?.missing_quantity,
                }
            })
        }
        else return []
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(document.getElementById("menuAnchor"));
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const filterDescription = 'Add filters to the list of results, and export the filtered list to CSV (currently not implemented for dataqualitysaporders)';


    const [sortDirection, setSortDirection] = React.useState('');
    const [sortParameter, setSortParameter] = React.useState('');

    const handleSortDataQualitySapOrders = (sortParameter) => {
        setSortParameter(sortParameter);
        if (sortDirection === '') {
            setSortDirection('ascending');
            dispatch(sortDataQualitySapOrders(sortParameter, 'ascending'));
        } else if (sortDirection === 'ascending') {
            setSortDirection('descending');
            dispatch(sortDataQualitySapOrders(sortParameter, 'descending'));
        } else {
            setSortDirection('ascending');
            dispatch(sortDataQualitySapOrders(sortParameter, 'ascending'));
        };
    };

    const [openBulkConfirmation, setOpenBulkConfirmation] = React.useState(false);

    const handleKeydown = (event) => {
        if (event.key == 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            setTimeout(() => {
                handleExecuteSearch();
            }, 0);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);

    return (
      <React.Fragment>
          <Modal
            open={openBulkConfirmation}
            style={{
                textAlign: "center",
                paddingTop: "45vh"
            }}>
              <>
                  <Typography
                    style={{ color: 'white' }}
                  >
                      This query could take a few minutes, are you sure you want to continue?
                  </Typography>
                  <div style={{ paddingBottom: '1%' }} />
                  <Grid container className='headercontainer'>
                      <Grid item>
                          <Button
                            style={{
                                color: 'white',
                                background: '#6b469e'
                            }}
                            onClick={() => { handleExecuteBulkSearch(); setOpenBulkConfirmation(false) }}
                          >Yes</Button>
                      </Grid>
                      <Grid item style={{ width: '7%' }} />
                      <Grid item>
                          <Button
                            style={{
                                color: 'white',
                                background: '#6b469e'
                            }}
                            onClick={() => setOpenBulkConfirmation(false)}
                          >No</Button>
                      </Grid>
                  </Grid>
              </>
          </Modal>
          <Modal
            open={openModal}
            style={{
                textAlign: "center",
                paddingTop: "45vh"
            }}>
              <>
                  <Typography
                    style={{ color: 'white' }}
                  >
                      The Search Date values are mandatory.
                  </Typography>
                  <div style={{ paddingBottom: '1%' }} />
                  <Grid container className='headercontainer'>
                      <Grid item>
                          <Button
                            style={{
                                color: 'white',
                                background: '#6b469e'
                            }}
                            onClick={() => setOpenModal(false)}
                          >Ok</Button>
                      </Grid>
                  </Grid>
              </>
          </Modal>
          <MultiValueInput
            setCurrentParam={setCurrentPartNumber}
            setQueryParams={setParameters}
            currentParam={currentPartNumber}
            queryParams={queryParameters?.partNumber}
            handleDelete={handleDeletePartNumber}
            handleClear={handleClearPartNumber}
            label="Part Number"
            family='dataQualitySapOrders'
            type='partNumber'
          />
          <MultiValueInput
            setCurrentParam={setCurrentSoldTo}
            setQueryParams={setParameters}
            currentParam={currentSoldTo}
            queryParams={queryParameters?.soldTos}
            handleDelete={handleDeleteSoldTo}
            handleClear={handleClearSoldTos}
            label="Sold To"
            family='dataQualitySapOrders'
            type='soldTos'
          />
          <MultiValueInput
            setCurrentParam={setCurrentOrderId}
            setQueryParams={setParameters}
            currentParam={currentOrderId}
            queryParams={queryParameters?.orderIds}
            handleDelete={handleDeleteOrderId}
            handleClear={handleClearOrderIds}
            label="Order ID"
            family='dataQualitySapOrders'
            type='orderIds'
          />
          <MultiValueInput
            setCurrentParam={setCurrentStatus}
            setQueryParams={setParameters}
            currentParam={currentStatus}
            queryParams={queryParameters?.status}
            handleDelete={handleDeleteStatus}
            handleClear={handleClearStatus}
            label="Status"
            family='dataQualitySapOrders'
            type='status'
          />
          <Grid>
              <SearchDateInput
                title={'Search Date'}
                setFilterDateStart={setParameters}
                setFilterDateEnd={setParameters}
                family='dataQualitySapOrders'
                type='creationDateStart'
                type2='creationDateEnd'
              />
          </Grid>
          <Grid
            container
            style={{
                justifyContent: 'center'
            }}
          >
              <Grid item>
                  <Button variant="contained" onClick={handleExecuteSearch} style={{ width: '15vw' }}>Search</Button>
              </Grid>
              <div style={{ paddingLeft: '0.2%' }} />
              <Grid item>
                  <Button variant="contained" onClick={() => setOpenBulkConfirmation(true)} style={{ width: '15vw' }}>Bulk Export</Button>
              </Grid>
          </Grid>
          <Title>DataQualitySapOrders</Title>
          <Typography>
              {dataqualitysaporders?.length ?
                dataqualitysaporders?.length === 100 ?
                  dataqualitysaporders?.length + ' results, use bulk export for more' :
                  dataqualitysaporders?.length + ' results' :
                null
              }
          </Typography>
          <Table size="small">
              <TableHead>
                  <TableRow>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  Creation Date
                              </Grid>
                              <Grid
                                item
                                onClick={() => handleSortDataQualitySapOrders('creation_date')}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'creation_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'creation_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Part Number"
                                    variant='standard'
                                    onChange={(e) => { setFilterPartNumber(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('part_number')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'part_number' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'part_number' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Sold To"
                                    variant='standard'
                                    onChange={(e) => { setFilterSoldTos(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('sold_to')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'sold_to' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'sold_to' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Status"
                                    variant='standard'
                                    onChange={(e) => { setFilterStatus(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('status')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'status' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'status' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Order ID"
                                    variant='standard'
                                    onChange={(e) => { setFilterOrderIds(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('order_id')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'order_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'order_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  <TextField
                                    label="Order Line"
                                    variant='standard'
                                    onChange={(e) => { setFilterOrderLines(e.target.value) }}
                                    onKeyDown={(e) => { e.stopPropagation(); }}
                                  />
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('order_line')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'order_line' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'order_line' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  Quantity
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('quantity')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'quantity' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'quantity' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  Real Quantity
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('real_quantity')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'real_quantity' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'real_quantity' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                      <TableCell>
                          <Grid container className='headercontainer'>
                              <Grid item>
                                  Missing quantity
                              </Grid>
                              <Grid item
                                    onClick={() => handleSortDataQualitySapOrders('missing_quantity')}
                                    style={{ alignSelf: 'center' }}
                              >
                                  {sortDirection === 'ascending' && sortParameter === 'missing_quantity' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                                    sortDirection === 'descending' && sortParameter === 'missing_quantity' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                                      <UnfoldMoreIcon className='sortbutton' />
                                  }
                              </Grid>
                          </Grid>
                      </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {dataqualitysaporders.map((row) => (
                    <TableRow
                      hover
                      key={row?.data_quality_sap_orders_identifier}
                      style={{
                          cursor: 'pointer'
                      }}>
                        <TableCell>{row?.creation_date}</TableCell>
                        <TableCell>{row?.part_number}</TableCell>
                        <TableCell>{row?.sold_to}</TableCell>
                        <TableCell>{row?.status}</TableCell>
                        <TableCell>{row?.order_id}</TableCell>
                        <TableCell>{row?.order_line}</TableCell>
                        <TableCell>{row?.quantity}</TableCell>
                        <TableCell>{row?.real_quantity}</TableCell>
                        <TableCell>{row?.missing_quantity}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
          </Table>
          <Tooltip
            title={<Typography fontSize={17}>{filterDescription}</Typography>}
          >
              <Fab
                style={{
                    position: 'absolute',
                    right: 10,
                    bottom: 10,
                    backgroundColor: "#6b469e",
                    color: "white",
                    height: "5em",
                    width: "5em"
                }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                  <div
                    id="menuAnchor"
                    style={{
                        position: 'relative',
                        bottom: 500,
                        right: 250
                    }}
                  >
                  </div>
                  <FileDownloadIcon fontSize="large" style={{ height: "70%", width: "70%" }} />
              </Fab>
          </Tooltip>
          <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
              >
                  <MenuItem >
                      <Grid>
                          <div>
                              Creation Date
                          </div>
                          <DateInput
                            setFilterDateStart={setFilterCreationDateStart}
                            setFilterDateEnd={setFilterCreationDateEnd}
                          />
                      </Grid>
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Part Number"
                        onChange={(e) => { setFilterPartNumber(e.target.value); }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Status"
                        onChange={(e) => { setFilterStatus(e.target.value) }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Sold To"
                        onChange={(e) => { setFilterSoldTos(e.target.value) }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
                  <MenuItem >
                      <TextField
                        label="Order Id"
                        onChange={(e) => { setFilterOrderIds(e.target.value) }}
                        onKeyDown={(e) => { e.stopPropagation(); }}
                      />
                  </MenuItem>
                  <MenuItem >
                      <Button variant="contained" onClick={() => {
                          handleExportToCSV(dataqualitysaporders)
                      }}>Export dataqualitysaporders</Button>
                  </MenuItem>
              </Menu>
          </div>
      </React.Fragment>
    );
}
