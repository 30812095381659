import * as React from 'react';
import Order from './tables/order';
import Product from './tables/product';
import SerialNumber from './tables/serialNumber';
import Warranty from './tables/warranty';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { extractAndDelete, formatDetailsKey } from '../utils';
import Extra from './tables/extra';

export default function SerialNumberDetails() {

    const location = useLocation();
    let serialNumber = location.pathname.split("/")[location.pathname.split("/").length - 1];

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const material = useSelector((state) => {
        let _material = state?.serialNumbers?.find(m => m.serial_number === serialNumber);
        if (_material) {
            return JSON.parse(JSON.stringify(_material));
        } else return {}
    });

    const materialClone = JSON.parse(JSON.stringify(material));

    const serialNumberFields = [
        { name: 'Serial Number', key: 'serial_number' },
        { name: 'Parent Serial Number', key: 'parent_serial_number'},
        { name: 'Part Number', key: 'part_number' },
        { name: 'Shipping Date', key: 'shipping_date' },
        { name: 'Extract Date', key: 'extract_date' },
        { name: 'Placeholder', key: 'placeholder'}
    ];

    const serialNumberDetails = serialNumberFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(materialClone, key) || ''
    }));

    const orderFields = [
        { name: 'Order ID', key: 'order_id' },
        { name: 'Order Line', key: 'order_line' },
        { name: 'Order Parent Line', key: 'order_parent_line' },
        { name: 'Orderable Item', key: 'orderable_item' },
        { name: 'Company ID', key: 'company_id' },
        { name: 'Customer Order ID', key: 'customer_order_id' },
        { name: 'Delegated To', key: 'delegated_to' },
        { name: 'End Customer ID', key: 'end_customer_id' },
        { name: 'Status', key: 'status' },
    ];

    const orderDetails = orderFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(materialClone, key) || ''
    }));

    const productFields = [
        { name: 'Product Type', key: 'product_type' },
        { name: 'Bundle Reference', key: 'bundle_reference' },
        { name: 'Bundle Master', key: 'bundle_master' },
        { name: 'Is NaaS', key: 'is_naas' },
        { name: 'Technical Item', key: 'technical_item' },
        { name: 'GSI1PK', key: 'GSI1PK' },
        { name: 'GSI1SK', key: 'GSI1SK' },
        { name: 'OI HWS', key: 'oi_hws' },
    ];

    const productDetails = productFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(materialClone, key) || ''
    }));

    const warrantyFields = [
        { name: 'Warranty Start Date', key: 'warranty_start_date' },
        { name: 'Warranty End Date', key: 'warranty_end_date' },
        { name: 'Service Level', key: 'warranty_service_level' },
    ];

    const warrantyDetails = warrantyFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(materialClone, key) || ''
    }));

    const extraDetails = Object.entries(materialClone).flatMap(([key, value]) => {
        if (key.toLocaleLowerCase() !== 'entitlements') {
            if (value && typeof value === 'object') {
                return Object.entries(value).map(([subKey, subValue]) => ({         // max 1 depth
                    name: `${formatDetailsKey(key)} - ${formatDetailsKey(subKey)}`,
                    value: subValue || ''
                }));
            } else {
                return [{
                    name: formatDetailsKey(key),
                    value: value || ''
                }];
            }
        } else {

        }
    });
    
    return (<>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <SerialNumber serialNumberDetails={serialNumberDetails}/>
            <div
                style={{ marginLeft: '1%' }}
            />
            <Order orderDetails={orderDetails} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Product productDetails={productDetails}/>
            <div
                style={{ marginLeft: '1%' }}
            />
            <Warranty warrantyDetails={warrantyDetails}/>
            <div
                style={{ marginLeft: '1%' }}
            />
            <Extra extraDetails={extraDetails}/>
        </div>
    </>)
}
