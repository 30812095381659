import {
    FILTER_PLACEHOLDER_MATERIALS,
    GET_PLACEHOLDER_MATERIALS,
    GET_PLACEHOLDER_MATERIALS_BULK,
    SORT_PLACEHOLDER_MATERIALS,
} from '../actions';
import {LOADING} from '../../global/actions'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {download, getNestedValueFromString} from '../../global/reducer';

const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
        hwsSnsTrail: {}
    },
};

function materialPlaceholderReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    switch (action.type){
        case LOADING:
            break;
        default:
            state.loading = false;
            break;
    };
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;

    switch (action.type) {
        case FILTER_PLACEHOLDER_MATERIALS:
            if (!state.placeholderMaterials) {
                state.placeholderMaterials = [];
            };
            let filterPlaceholderMaterialParams = action.data;
            let filteredPlaceholderMaterials = [];
            let newPlaceholderMaterials = state.placeholderMaterials.filter(m => {
                if (filterPlaceholderMaterialParams?.serialNumbers?.length > 0) {
                    for (let serialNumber of filterPlaceholderMaterialParams?.serialNumbers) {
                        if (!m?.serial_number.startsWith(serialNumber)) {
                            return false;
                        };
                    };
                };
                if (filterPlaceholderMaterialParams?.partNumbers?.length > 0) {
                    for (let partNumber of filterPlaceholderMaterialParams?.partNumbers) {
                        if (!m?.part_number.startsWith(partNumber)) {
                            return false;
                        };
                    };
                }
                if (filterPlaceholderMaterialParams?.soldTos?.length > 0) {
                    for (let soldTo of filterPlaceholderMaterialParams?.soldTos) {
                        if (!m?.sold_to?.applicant_id.startsWith(soldTo)) {
                            return false;
                        };
                    };
                }
                if (filterPlaceholderMaterialParams?.businessPartners?.length > 0) {
                    for (let businessPartner of filterPlaceholderMaterialParams?.businessPartners) {
                        if (!m?.business_partner?.company_id.startsWith(businessPartner)) {
                            return false;
                        };
                    };
                }
                if (filterPlaceholderMaterialParams?.orderIds?.length > 0) {
                    for (let order_id of filterPlaceholderMaterialParams?.orderIds) {
                        if (!m?.order?.order_id.startsWith(order_id)) {
                            return false;
                        };
                    };
                }
                if (filterPlaceholderMaterialParams?.orderLines?.length > 0) {
                    for (let order_line of filterPlaceholderMaterialParams?.orderLines) {
                        if (!m?.order?.order_line?.startsWith(order_line)) {
                            return false;
                        };
                    };
                }
                if (filterPlaceholderMaterialParams?.filterCreationDateStart) {
                    if (!moment(filterPlaceholderMaterialParams.filterCreationDateStart).isBefore(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterPlaceholderMaterialParams?.filterCreationDateEnd) {
                    if (!moment(filterPlaceholderMaterialParams.filterCreationDateEnd).isAfter(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterPlaceholderMaterialParams?.filterModificationDateStart) {
                    if (!moment(filterPlaceholderMaterialParams.filterModificationDateStart).isBefore(moment(m.modification_date))) {
                        return false
                    }
                };
                if (filterPlaceholderMaterialParams?.filterModificationDateEnd) {
                    if (!moment(filterPlaceholderMaterialParams.filterModificationDateEnd).isAfter(moment(m.modification_date))) {
                        return false
                    }
                };
                return true
            });
            filteredPlaceholderMaterials = filteredPlaceholderMaterials.concat(newPlaceholderMaterials)

            state = Object.assign({}, state, { filteredPlaceholderMaterials });
            console.log('filteredPlaceholderMaterials', filteredPlaceholderMaterials);
            return state;
        case SORT_PLACEHOLDER_MATERIALS:
            console.log("SORT_PLACEHOLDER_MATERIALS", action.data);
            const placeholderMaterialParam = action.data.sortParameter;
            const placeholderMaterialDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredPlaceholderMaterials?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, placeholderMaterialParam)?.toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, placeholderMaterialParam)?.toString()?.toUpperCase();
                if (placeholderMaterialDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state
        case GET_PLACEHOLDER_MATERIALS:
            console.log("GET_PLACEHOLDER_MATERIALS", action.results);
            state = Object.assign({}, state, { placeholderMaterials: action.results, filteredPlaceholderMaterials: action.results });
            return state;
        case GET_PLACEHOLDER_MATERIALS_BULK:
            console.log("GET_PLACEHOLDER_MATERIALS_BULK", action.CSVMaterials);
            state = Object.assign({}, state, { CSVMaterials: action.CSVMaterials });
            download(uuidv4() + ".csv", action.CSVMaterials);
            return state;
    }
    return state;
}

export default materialPlaceholderReducer
