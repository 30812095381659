import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import Asset from './tables/asset';
import Contracts from './tables/contracts';
import { useDispatch } from 'react-redux';
import { getMaterialDetails } from '../redux/material/actions';
import { getContractDetails } from '../redux/contract/actions';
import SerialNumberReference from './tables/serialNumberReference';
import Order from './tables/order';
import Client from './tables/client';
import { extractAndDelete, formatDetailsKey } from '../utils';
import Extra from './tables/extra';


export default function MaterialDetails() {

    const dispatch = useDispatch();
    const location = useLocation();
    let serialNumber = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const material = useSelector((state) => {
        let _material = state?.materials?.find(m => m.serial_number === serialNumber);
        if (_material) {
            return JSON.parse(JSON.stringify(_material));
        };
    });
    let orderId = material?.order_id
    let orderLine = material?.order_line
    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!material && !loading && !errors) {
            dispatch(getMaterialDetails(serialNumber, orderId, orderLine));
        };
    }, []);

    const materialClone = JSON.parse(JSON.stringify(material));

    const assetFields = [
        { name: 'Serial Number', key: 'serial_number' },
        { name: 'Comments', key: 'comments' },
        { name: 'Creation Date', key: 'creation_date' },
        { name: 'Creation Note', key: 'creation_note' },
        { name: 'Description', key: 'description' },
        { name: 'Inactive Reason', key: 'inactive_reason' },
        { name: 'Modification Date', key: 'modification_date' },
        { name: 'Part Number', key: 'part_number' },
        { name: 'Serial Number Type', key: 'sn_type' },
        { name: 'Source Time', key: 'source_time' },
        { name: 'Status', key: 'status' },
    ];

    const assetDetails = assetFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(materialClone, key) || ''
    }));

    const clientFields = [
        { name: 'Support Partner', key: 'support_partner.company_id', hover: 'support_partner.source' },
        { name: 'End User', key: 'end_user.company_id', hover: 'end_user.source' },
        { name: 'Sold To', key: 'sold_to.applicant_id' },
        { name: 'Business Partner', key: 'business_partner.company_id', hover: 'business_partner.source' },
    ];

    const clientDetails = clientFields.map(({ name, key, hover }) => {
        const value = extractAndDelete(materialClone, key) || '';
        const hoverValue = hover ? hover.split('.').reduce((acc, key) => acc?.[key], material) : '';
        return {
            name,
            value,
            hover: hoverValue,
        };
    });

    const orderDetails = [
        { name: 'Order ID', value: material.order.order_id},
        { name: 'Order Line', value: material.order.order_line},
        { name: 'Order Line Type', value: material.order.order_line_type},
        { name: 'Order Line Type ID', value: material.order.order_line_type_id},
        { name: 'Parent Order Line', value: material.order.parent_order_line},
        { name: 'Part Description', value: material.order.part_description},
        { name: 'Part Number', value: material.order.part_number},
        { name: 'Quantity', value: material.order.quantity},
        { name: 'Sold To', value: material.order.sold_to},
        { name: 'Purchase Order', value: material.order.purchase_order}
    ];

    const rawSAPDetails = [
        { name: 'Customer Group 3', value: material?.serialNumberReference?.customer_group3},
        { name: 'Customer Order ID', value: material?.serialNumberReference?.customer_order_id},
        { name: 'Delegated To', value: material?.serialNumberReference?.delegated_to},
        { name: 'Extract Date', value: material?.serialNumberReference?.extract_date},
        { name: 'Order Parent Line', value: material?.serialNumberReference?.order_parent_line},
        { name: 'Product Type', value: material?.serialNumberReference?.product_type},
        { name: 'Shipping Date', value: material?.serialNumberReference?.shipping_date},
        { name: 'Warranty End Date', value: material?.serialNumberReference?.warranty_end_date},
        { name: 'Warranty Service Level', value: material?.serialNumberReference?.warranty_service_level},
        { name: 'Warranty Start Date', value: material?.serialNumberReference?.warranty_start_date},
        { name: 'Orderable Item', value: material?.serialNumberReference?.orderable_item},
        { name: 'Technical Item', value: material?.serialNumberReference?.technical_item},
    ];

    const enrichedSAPDetails = [
        { name: 'Bundle Master', value: material?.serialNumberReference?.bundle_master?.toString() },
        { name: 'Bundle Reference', value: material?.serialNumberReference?.bundle_reference },
    ];

    delete materialClone.serialNumberReference;
    delete materialClone.order;

    const extraDetails = Object.entries(materialClone).flatMap(([key, value]) => {
        if (key.toLocaleLowerCase() !== 'entitlements' && key.toLocaleLowerCase() !== 'hardware_support_entitlement') { // TODO: This could be nicer
            if (value && typeof value === 'object') {
                return Object.entries(value).flatMap(([subKey, subValue]) => {
                    if (subValue && typeof subValue === 'object') {
                        // Handle subValue as an object - decide what to do
                        return Object.entries(subValue).map(([innerKey, innerValue]) => ({
                            name: `${formatDetailsKey(key)} - ${formatDetailsKey(subKey)} - ${formatDetailsKey(innerKey)}`,
                            value: innerValue || ''
                        }));
                    }
                });
            }
             else {
                return [{
                    name: formatDetailsKey(key),
                    value: value || ''
                }];
            }
        } else {

        }
    });


    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Asset assetDetails={assetDetails} parent={material?.parent} children={material?.children} />
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Client clientDetails={clientDetails} />
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Order orderDetails={orderDetails} />
                <div
                    style={{ marginLeft: '1%' }}
                />
                <SerialNumberReference rawSAPDetails={rawSAPDetails} enrichedSAPDetails={enrichedSAPDetails}/>
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Extra extraDetails={extraDetails} />
            </div>
            <div
                style={{ marginBottom: '1%' }}
            />
            <Contracts getContractDetails={getContractDetails} />
        </>
    )
};
