import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMaterialDetails } from '../../redux/material/actions';


export default function HwsOutTrail(props) {

    const hwsSnsTrail = props?.hwsSnsTrail;


    return (
        <React.Fragment>
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '30%' }} size="small" aria-label="contracts table">
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                HWS Out departure date
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                HWS Out Serial number
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                HWS Out Part Number
                            </TableCell>
                            <TableCell align="center" style={{ fontWeight: "bolder" }}>
                                HWS Out Business partner
                            </TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            <TableCell align="center">{hwsSnsTrail?.hws_out_departure_date}</TableCell>
                            <TableCell align="center">{hwsSnsTrail?.serial_number_hws_out}</TableCell>
                            <TableCell align="center">{hwsSnsTrail?.part_number_hws_out}</TableCell>
                            <TableCell align="center">{hwsSnsTrail?.business_partner_hws_out}</TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );

}