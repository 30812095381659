export const extractAndDelete = (obj, keyPath) => {     // removes a deeply-nested attribute using dot notation
    const parts = keyPath.split('.');
    let current = obj;
    for (let i = 0; i < parts.length - 1; i++) {
        if (!current[parts[i]]) {   // attribute doesn't exist
            return undefined
        };
        current = current[parts[i]];
    }
    const lastKey = parts[parts.length - 1];
    const value = current?.[lastKey];
    delete obj[parts[0]];
    return value?.toString();       // also returns value of given element for display purposes
};

export const formatDetailsKey = (key) => {
    return key
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};