import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import { getOrders } from '../redux/dynamo_db/actions';
import Customer from './tables/customer';
import Order from './tables/order';
import { extractAndDelete, formatDetailsKey } from '../utils';
import Extra from './tables/extra';


export default function OrderDetails() {

    const dispatch = useDispatch();
    const location = useLocation();
    let orderId = location.pathname.split("/")[location.pathname.split("/").length - 2];
    let orderLine = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const order = useSelector((state) => {
        let _order = state?.orders?.find(c => c.order_id === orderId);
        if (_order) {
            return JSON.parse(JSON.stringify(_order));
        } else return {}
    });

    const orderClone = JSON.parse(JSON.stringify(order))

    const orderFields = [
        { name: 'Order ID', key: 'order_id' },
        { name: 'Order Line', key: 'order_line' },
        { name: 'Order Line Type', key: 'order_line_type' },
        { name: 'Order Line Type ID', key: 'order_line_type_id' },
        { name: 'Parent Order Line', key: 'parent_order_line' },
        { name: 'Part Description', key: 'part_description' },
        { name: 'Part Number', key: 'part_number' },
        { name: 'Quantity', key: 'quantity' },
        { name: 'Sold To', key: 'sold_to' },
        { name: 'Purchase Order', key: 'purchase_order' },
        { name: 'Order Date', key: 'order_date' }
    ];

    const orderDetails = orderFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(orderClone, key) || ''
    }));

    const customerFields = [
        { name: 'Customer Name', key: 'end_customer_name' },
        { name: 'Customer Sector', key: 'end_customer_sector' },
        { name: 'Customer Sub-Sector', key: 'end_customer_sub_sector' },
        { name: 'Customer Country', key: 'end_customer_country' },
        { name: 'Customer City', key: 'end_customer_city' },
        { name: 'Customer Address', key: 'end_customer_address' },
        { name: 'Customer Zip', key: 'end_customer_zip' },
    ];

    const customerDetails = customerFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(orderClone, key) || ''
    }));

    const extraDetails = Object.entries(orderClone).flatMap(([key, value]) => {
        if (key.toLocaleLowerCase() !== 'entitlements') {
            if (value && typeof value === 'object') {
                return Object.entries(value).map(([subKey, subValue]) => ({         // max 1 depth
                    name: `${formatDetailsKey(key)} - ${formatDetailsKey(subKey)}`,
                    value: subValue || ''
                }));
            } else {
                return [{
                    name: formatDetailsKey(key),
                    value: value || ''
                }];
            }
        } else {

        }
    });

    return (<>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Order orderDetails={orderDetails} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Customer customerDetails={customerDetails} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Extra extraDetails={extraDetails} />
        </div>
    </>)
}
