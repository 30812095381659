import * as React from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import {extractAndDelete} from '../utils';
import HwsIn from "./tables/hwsIn";
import HwsOut from "./tables/hwsout";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {exportHwsSwapToCSV} from "../redux/dynamo_db/actions";


export default function HwsSwapDetail() {
  const dispatch = useDispatch();
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(null);

  const location = useLocation();
  let hwsIdentifier = location.pathname.split("/")[location.pathname.split("/").length - 1];

  // The parse/stringify cheap cloning trick is to create a local copy of the state object
  const hwsSnTrail = useSelector((state) => {
    let _hwsSnTrail = state?.hwsSnsTrail?.find(c => c.SK === hwsIdentifier);
    if (_hwsSnTrail) {
      return JSON.parse(JSON.stringify(_hwsSnTrail));
    } else return {}
  });
  const handleExportToCsv = () => {
    const hwsSwapSns = {
      "serial_number_hws_in": hwsSnTrail?.serial_number_hws_in,
      "business_partner_hws_in": hwsSnTrail?.business_partner_hws_in,
      "serial_number_hws_out": selectedSerialNumber === hwsSnTrail?.serial_number_hws_out ? hwsSnTrail?.serial_number_hws_out : null ,
    };
    dispatch(exportHwsSwapToCSV(hwsSwapSns));
  };

  const hwsSnTrailClone = JSON.parse(JSON.stringify(hwsSnTrail))
  const hwsSnTrailInFields = [
    { name: 'Serial Number', key: 'serial_number_hws_in' },
    { name: 'Arrival date', key: 'hws_in_arrival_date' },
    { name: 'Order ID', key: 'hws_order_id' },
    { name: 'Base Order Line', key: 'hws_base_order_line' },
    { name: 'Part Number', key: 'part_number_hws_in' },
    { name: 'Applicant Id', key: 'applicant_id_hws_in' },
    { name: 'Business Partner CRD Id', key: 'business_partner_hws_in' },
    { name: 'Support Partner CRD Id', key: 'support_partner_hws_in' },
    { name: 'End Customer Id', key: 'end_customer_hws_in' },
    { name: 'Bundle reference', key: 'bundle_reference_hws_in' },
  ];

  const hwsSnTrailInDetails = hwsSnTrailInFields.map(({ name, key }) => ({
    name,
    value: extractAndDelete(hwsSnTrailClone, key) || ''
  }));

  return (<>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <HwsIn hwsSnTrailInDetails={hwsSnTrailInDetails}/>
      <div
      />
    </div>
    <div
      style={{marginBottom: '1%'}}
    />
    <HwsOut hwsSnTrailClone={[hwsSnTrailClone]} selectedSerialNumber={selectedSerialNumber} setSelectedSerialNumber={setSelectedSerialNumber}/>
    <Grid item>
      <Button variant="contained" onClick={handleExportToCsv} style={{ width: '15vw' }}>Export</Button>
    </Grid>
  </>)
}
