import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from '@mui/material';
import { Accordion, AccordionDetails, Typography, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Client(props) {
    const clientDetails = props?.clientDetails;

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>
                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <PersonIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Client
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="client table">
                                <TableBody>
                                    {clientDetails.map((row, i) => {
                                        return (
                                            <>
                                                {row?.value ? <Tooltip
                                                    title={row?.hover ? row?.hover : ""}
                                                    placement='right'
                                                >
                                                    <TableRow>
                                                        <TableCell key={row.name + i + " namecell"}>
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell key={row.value + i + " valuecell"}>
                                                            {row.value}
                                                        </TableCell>
                                                    </TableRow>
                                                </Tooltip> : null}
                                            </>

                                        )

                                    })}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </TableContainer>
            </div>
        </React.Fragment >
    );
}