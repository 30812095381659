import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import SystemApplication from './tables/systemApplication';
import Contracts from './tables/contracts';
import Customer from './tables/customer';
import Extra from './tables/extra';
import { getSystemApplicationDetails } from '../redux/application/actions';
import { getContractDetails } from '../redux/contract/actions';
import { extractAndDelete, formatDetailsKey } from '../utils';

export default function SystemApplicationDetails() {

    const dispatch = useDispatch();
    const location = useLocation();

    let applicationId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const systemApplication = useSelector((state) => {
        let _systemApplication = state?.systemApplications?.find(s => s.application_id === applicationId);
        if (_systemApplication) {
            return JSON.parse(JSON.stringify(_systemApplication));
        } else return {}
    });
    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!systemApplication && !loading && !errors) {
            dispatch(getSystemApplicationDetails(applicationId));
        };
    }, []);

    const systemApplicationClone = JSON.parse(JSON.stringify(systemApplication));
    
    const systemApplicationFields = [
        { name: 'Application ID', key: 'application_id' },
        { name: 'Application Family', key: 'application_family' },
        { name: 'Comments', key: 'comments' },
        { name: 'Creation Date', key: 'creation_date' },
        { name: 'Creation Note', key: 'creation_note' },
        { name: 'Description', key: 'description' },
        { name: 'End User', key: 'end_user.company_id' },
        { name: 'Inactive Reason', key: 'inactive_reason' },
        { name: 'Modification Date', key: 'modification_date' },
        { name: 'Order ID', key: 'order.order_id' },
        { name: 'Part Number', key: 'part_number' },
        { name: 'Product', key: 'product' },
        { name: 'Type', key: 'type' }
    ];

    const systemApplicationDetails = systemApplicationFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(systemApplicationClone, key) || ''
    }));

    
    const customerDetails = [
        { name: 'Business Partner', value: systemApplicationClone.business_partner.company_id},
        { name: 'Support Partner', value: systemApplicationClone.support_partner.company_id},
        { name: 'Sold To', value: systemApplicationClone.sold_to.applicant_id}
    ];

    delete systemApplicationClone.business_partner;
    delete systemApplicationClone.support_partner;
    delete systemApplicationClone.sold_to;

    const extraDetails = Object.entries(systemApplicationClone).flatMap(([key, value]) => {
        if (key.toLocaleLowerCase() !== 'entitlements') {
            if (value && typeof value === 'object') {
                return Object.entries(value).map(([subKey, subValue]) => ({         // max 1 depth
                    name: `${formatDetailsKey(key)} - ${formatDetailsKey(subKey)}`,
                    value: subValue || ''
                }));
            } else {
                return [{
                    name: formatDetailsKey(key),
                    value: value || ''
                }];
            }
        } else {

        }
    });

    return (<>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <SystemApplication systemApplicationDetails={systemApplicationDetails} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Customer customerDetails={customerDetails} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Extra extraDetails={extraDetails} />
        </div>
        <div
            style={{ marginBottom: '1%' }}
        />
        <Contracts getContractDetails={getContractDetails} />
    </>)
}
