import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import Asset from './tables/asset';
import Contracts from './tables/contracts';
import { useDispatch } from 'react-redux';
import { getMaterialDetails, getCandidateMaterials } from '../redux/material/actions';
import { getContractDetails } from '../redux/contract/actions';
import Order from './tables/order';
import Client from './tables/client';
import InitialOrder from './tables/initialOrder';
import Extra from './tables/extra';
import { Grid } from '@mui/material';
import Candidates from './tables/candidates';
import { extractAndDelete, formatDetailsKey } from '../utils';


export default function PlaceholderMaterialDetails() {

    const dispatch = useDispatch();
    const location = useLocation();
    let serialNumber = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const material = useSelector((state) => {
        let _material = state?.placeholderMaterials?.find(m => m.serial_number === serialNumber);
        if (_material) {
            return JSON.parse(JSON.stringify(_material));
        };
    });
    const orderId = material?.order?.order_id
    const orderLine = material?.order?.order_line
    const candidateMaterials = useSelector(state => state.potentialCandidates);

    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!loading && !errors) {
            if (!material) {
                dispatch(getMaterialDetails(serialNumber, orderId, orderLine));
            };
        };
    }, []);

    // This setState call is wrapped in a useEffect
    React.useEffect(() => {
        if (!candidateMaterials) {
            dispatch(getCandidateMaterials({ order: orderId }));
        }
    }, [orderId]);

    const materialClone = JSON.parse(JSON.stringify(material))

    const assetFields = [
        { name: 'Serial Number', key: 'serial_number' },
        { name: 'Comments', key: 'comments' },
        { name: 'Creation Date', key: 'creation_date' },
        { name: 'Creation Note', key: 'creation_note' },
        { name: 'Description', key: 'description' },
        { name: 'Inactive Reason', key: 'inactive_reason' },
        { name: 'Modification Date', key: 'modification_date' },
        { name: 'Part Number', key: 'part_number' },
        { name: 'Serial Number Type', key: 'sn_type' },
        { name: 'Source Time', key: 'source_time' },
        { name: 'Status', key: 'status' },
        { name: 'Shipping Date', key: 'shipping_date' },
        { name: 'Bundle Master', key: 'bundle_master' },
        { name: 'Bundle Reference', key: 'bundle_reference' },
    ];

    const assetDetails = assetFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(materialClone, key) || ''
    }));

    const clientDetails = [
        { name: 'Support Partner', value: material?.support_partner?.company_id, hover: material?.support_partner?.source },
        { name: 'End User', value: material?.end_user?.company_id, hover: material?.end_user?.source },
        { name: 'Sold To', value: material?.sold_to?.applicant_id },
        { name: 'Business Partner', value: material?.business_partner?.company_id, hover: material?.business_partner?.source },
    ];

    delete materialClone?.support_partner;
    delete materialClone?.end_user;
    delete materialClone?.sold_to;
    delete materialClone?.business_partner;

    const order = materialClone?.order;
    const orderDetails = [
        { name: 'Order ID', value: order?.order_id },
        { name: 'Order Line', value: order?.order_line },
        { name: 'Order Line Type', value: order?.order_line_type },
        { name: 'Order Line Type ID', value: order?.order_line_type_id },
        { name: 'Parent Order Line', value: order?.parent_order_line },
        { name: 'Part Description', value: order?.part_description },
        { name: 'Part Number', value: order?.part_number },
        { name: 'Quantity', value: order?.quantity },
        { name: 'Sold To', value: order?.sold_to },
        { name: 'Purchase Order', value: order?.purchase_order }
    ];
    delete materialClone?.order;

    const initialOrderFields = [
        { name: 'Order ID', key: 'old_order_id' },
        { name: 'Purchase Order', key: 'old_purchase_order' },
    ];

    const initialOrderDetails = initialOrderFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(materialClone, key) || ''
    }));

    const extraDetails = Object.entries(materialClone).flatMap(([key, value]) => {
        if (key.toLocaleLowerCase() !== 'entitlements') {
            if (value && typeof value === 'object') {
                return Object.entries(value).map(([subKey, subValue]) => ({         // max 1 depth
                    name: `${formatDetailsKey(key)} - ${formatDetailsKey(subKey)}`,
                    value: subValue || ''
                }));
            } else {
                return [{
                    name: formatDetailsKey(key),
                    value: value || ''
                }];
            }
        } else {

        }
    });

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <Asset assetDetails={assetDetails} parent={material?.parent} children={material?.children} />
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Grid container flexDirection={'column'}>
                    <Grid item>
                        <Client clientDetails={clientDetails} />
                    </Grid>
                    <div
                        style={{ marginTop: '1%' }}
                    />
                    <Grid item>
                        <Candidates />
                    </Grid>
                </Grid>
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Grid container flexDirection={'column'}>
                    <Grid item>
                        <Order orderDetails={orderDetails} />
                    </Grid>
                    <div
                        style={{ marginTop: '1%' }}
                    />
                    <Grid item>
                        <InitialOrder initialOrderDetails={initialOrderDetails} />
                    </Grid>
                </Grid>
                <div
                    style={{ marginLeft: '1%' }}
                />
                <Extra extraDetails={extraDetails} />
            </div>
            <div
                style={{ marginBottom: '1%' }}
            />
            <Contracts getContractDetails={getContractDetails} />
        </>
    )
};
