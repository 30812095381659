import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Radio, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HwsOut(props) {
    const hwsSnTrailClone = props?.hwsSnTrailClone;
    const selectedSerialNumber = props.selectedSerialNumber;
    const setSelectedSerialNumber = props.setSelectedSerialNumber;
    const hwsSnTrailOutFields = [
        { name: 'Select' },
        { name: 'Serial Number' },
        { name: 'Departure date' },
        { name: 'Part Number' },
        { name: 'Applicant Id' },
        { name: 'Business Partner CRD Id' },
        { name: 'Support Partner CRD Id' },
        { name: 'End Customer Id' },
        { name: 'Bundle reference' },
    ];

    const handleSelection = (serialNumber) => {
        if(selectedSerialNumber === serialNumber){
            setSelectedSerialNumber(null);
        }
        else{
            setSelectedSerialNumber(serialNumber);
        }
    };

    return (
      <React.Fragment>
          <div style={{ width: '100%' }}>
              <TableContainer component={Paper}>
                  <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        style={{
                            backgroundColor: "#6b469e",
                            color: "white",
                            height: '3vh'
                        }}
                        sx={{
                            "&.Mui-expanded": {
                                minHeight: 0
                            },
                            "&.MuiAccordionSummary-root": {
                                minHeight: 0
                            },
                        }}
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                      >
                          <Typography style={{ width: '100%' }}>
                              <ReceiptIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                              Hardware Support Out serial numbers
                          </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                          <Table sx={{ minWidth: '30%' }} size="small" aria-label="hws in sn data">
                              <TableRow>
                                  {hwsSnTrailOutFields?.map((field) => (
                                    <TableCell key={field.name} align="center" style={{ fontWeight: "bolder" }}>
                                        {field.name}
                                    </TableCell>
                                  ))}
                              </TableRow>
                              {hwsSnTrailClone?.map((hwsSnTrailOutDetail) => {
                                  const valueRows = [
                                      hwsSnTrailOutDetail?.serial_number_hws_out,
                                      hwsSnTrailOutDetail?.hws_out_departure_date,
                                      hwsSnTrailOutDetail?.part_number_hws_out,
                                      hwsSnTrailOutDetail?.applicant_id_hws_out,
                                      hwsSnTrailOutDetail?.business_partner_hws_out,
                                      hwsSnTrailOutDetail?.support_partner_hws_out,
                                      hwsSnTrailOutDetail?.end_customer_hws_out,
                                      hwsSnTrailOutDetail?.bundle_reference_hws_out,
                                  ];
                                  return (
                                    <TableRow
                                      key={hwsSnTrailOutDetail?.serial_number_hws_out}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                      hover
                                      style={{ cursor: 'pointer' }}
                                    >
                                        <TableCell align="center">
                                            <FormControlLabel
                                              control={
                                                  <Radio
                                                    checked={selectedSerialNumber === hwsSnTrailOutDetail?.serial_number_hws_out}
                                                    onClick={() => handleSelection(hwsSnTrailOutDetail?.serial_number_hws_out)}
                                                    value={hwsSnTrailOutDetail?.serial_number_hws_out}
                                                  />
                                              }
                                              label=""
                                            />
                                        </TableCell>
                                        {valueRows?.map((value, i) => (
                                          <TableCell key={i} align="center" style={{ position: 'relative' }}>
                                              {value}
                                          </TableCell>
                                        ))}
                                    </TableRow>
                                  );
                              })}
                          </Table>
                      </AccordionDetails>
                  </Accordion>
              </TableContainer>
          </div>
      </React.Fragment>
    );
}
