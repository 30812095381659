import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import { useSelector } from 'react-redux';
import {
  getMaterialDetails,
  getMaterials,
  exportMaterialsToCSV,
  filterMaterials,
  sortMaterials,
  getMaterialsBulk,
  exportMaterialContractsToCSV,
} from '../../redux/material/actions';
import {
  setParameters,
  removeParameter,
  clearParameters
} from '../../redux/global/actions';
import {
  useNavigate,
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import MultiValueInput from '../../inputs/multiValueInput';
import DateInput from '../../inputs/dateInput';
import { Accordion, AccordionDetails, AccordionSummary, Fab, Grid, IconButton, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect } from 'react';
import MultiValueInputWithWarning from '../../inputs/multiValueInputWithWarning';
import './Materials.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import SearchDateInput from '../../inputs/searchDateInput';
import MaterialChildren from './materialChildren';
import MaterialParents from './materialParents';

export default function Materials(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParameters = useSelector(state => state?.parameters?.materials);

  const [currentSerialNumber, setCurrentSerialNumber] = React.useState();
  const [currentBusinessPartner, setCurrentBusinessPartner] = React.useState();
  const [currentSoldTo, setCurrentSoldTo] = React.useState();
  const [currentOrder, setCurrentOrder] = React.useState();
  const [currentEndUser, setCurrentEndUser] = React.useState();
  const [currentBundleReference, setCurrentBundleReference] = React.useState();
  const [currentContractReferences, setCurrentContractReferences] = React.useState();
  const [currentSupportPartner, setCurrentSupportPartner] = React.useState();

  const [filterSerialNumbers, setFilterSerialNumbers] = React.useState([]);
  const [filterPartNumbers, setFilterPartNumbers] = React.useState([]);
  const [filterSoldTos, setFilterSoldTos] = React.useState([]);
  const [filterBusinessPartners, setFilterBusinessPartners] = React.useState([]);
  const [filterOrderIds, setfilterOrderIds] = React.useState([]);
  const [filterCreationDateStart, setFilterCreationDateStart] = React.useState();
  const [filterCreationDateEnd, setFilterCreationDateEnd] = React.useState();
  const [filterModificationDateStart, setFilterModificationDateStart] = React.useState();
  const [filterModificationDateEnd, setFilterModificationDateEnd] = React.useState();

  const handleExecuteSearch = () => {
    let queryObject = {}
    if (queryParameters?.serialNumbers) {
      queryObject.serial_number = queryParameters?.serialNumbers;
    };
    if (queryParameters?.businessPartners) {
      queryObject.business_partner = queryParameters?.businessPartners;
    };
    if (queryParameters?.soldTos) {
      queryObject.sold_to = queryParameters?.soldTos;
    };
    if (queryParameters?.order) {
      queryObject.order = queryParameters?.order;
    };
    if (queryParameters?.endUser) {
      queryObject.end_user = queryParameters?.endUser;
    };
    if (queryParameters?.bundleReference) {
      queryObject.bundle_reference = queryParameters?.bundleReference;
    };
    if (queryParameters?.contractReferences) {
      queryObject.contract_reference = queryParameters?.contractReferences;
    };
    if (queryParameters?.supportPartner) {
      queryObject.support_partner = queryParameters?.supportPartner;
    };
    if (queryParameters?.creationDateStart && queryParameters?.creationDateEnd) {
      queryObject.creation_date_from = queryParameters?.creationDateStart;
      queryObject.creation_date_to = queryParameters?.creationDateEnd;
    };
    dispatch(getMaterials(queryObject));
  };

  const handleExecuteBulkSearch = () => {
    let queryObject = {}
    if (queryParameters?.serialNumbers) {
      queryObject.serial_number = queryParameters?.serialNumbers;
    };
    if (queryParameters?.businessPartners) {
      queryObject.business_partner = queryParameters?.businessPartners;
    };
    if (queryParameters?.soldTos) {
      queryObject.sold_to = queryParameters?.soldTos;
    };
    if (queryParameters?.order) {
      queryObject.order = queryParameters?.order;
    };
    if (queryParameters?.endUser) {
      queryObject.end_user = queryParameters?.endUser;
    };
    if (queryParameters?.bundleReference) {
      queryObject.bundle_reference = queryParameters?.bundleReference;
    };
    if (queryParameters?.contractReferences) {
      queryObject.contract_reference = queryParameters?.contractReferences;
    };
    if (queryParameters?.supportPartner) {
      queryObject.support_partner = queryParameters?.supportPartner;
    };
    if (queryParameters?.creationDateStart && queryParameters?.creationDateEnd) {
      queryObject.creation_date_from = queryParameters?.creationDateStart;
      queryObject.creation_date_to = queryParameters?.creationDateEnd;
    };
    dispatch(getMaterialsBulk(queryObject));
  };

  const handleExecuteFilter = () => {
    let filterObject = {}
    if (filterSerialNumbers?.length > 0) {
      filterObject.serialNumbers = [filterSerialNumbers];
    };
    if (filterBusinessPartners?.length > 0) {
      filterObject.businessPartners = [filterBusinessPartners];
    };
    if (filterSoldTos?.length > 0) {
      filterObject.soldTos = [filterSoldTos];
    };
    if (filterPartNumbers?.length > 0) {
      filterObject.partNumbers = [filterPartNumbers];
    };
    if (filterOrderIds?.length > 0) {
      filterObject.orderIds = [filterOrderIds];
    };
    if (filterCreationDateStart) {
      filterObject.filterCreationDateStart = filterCreationDateStart;
    };
    if (filterCreationDateEnd) {
      filterObject.filterCreationDateEnd = filterCreationDateEnd;
    };
    dispatch(filterMaterials(filterObject));
  };


  useEffect(() => {
    handleExecuteFilter()
  }, [
    filterSerialNumbers,
    filterPartNumbers,
    filterSoldTos,
    filterBusinessPartners,
    filterOrderIds,
    filterCreationDateStart,
    filterCreationDateEnd,
    filterModificationDateStart,
    filterModificationDateEnd
  ]);

  function handleSelectMaterial(serialNumber, orderId, orderLine) {
    dispatch(getMaterialDetails(serialNumber, orderId, orderLine));
    navigate('/materials/details/' + serialNumber);
  };

  const handleDeleteSerialNumber = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { serialNumbers: [chipToDelete] } }));
  };

  const handleClearSerialNumbers = () => {
    dispatch(clearParameters({ materials: 'serialNumbers' }));
  };

  const handleDeleteBusinessPartner = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { businessPartners: [chipToDelete] } }));
  };

  const handleClearBusinessPartners = () => {
    dispatch(clearParameters({ materials: 'businessPartners' }));
  };

  const handleDeleteSoldTo = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { soldTos: [chipToDelete] } }));
  };

  const handleClearSoldTo = () => {
    dispatch(clearParameters({ materials: 'soldTos' }));
  };

  const handleDeleteOrder = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { order: [chipToDelete] } }));
  };

  const handleClearOrder = () => {
    dispatch(clearParameters({ materials: 'order' }));
  };

  const handleDeleteEndUser = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { endUser: [chipToDelete] } }));
  };

  const handleClearEndUser = () => {
    dispatch(clearParameters({ materials: 'endUser' }));
  }

  const handleDeleteBundleReference = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { bundleReference: [chipToDelete] } }));
  };

  const handleClearBundleReference = () => {
    dispatch(clearParameters({ materials: 'bundleReference' }));
  };

  const handleDeleteContractReference = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { contractReferences: [chipToDelete] } }));
  };

  const handleClearContractReference = () => {
    dispatch(clearParameters({ materials: 'contractReferences' }));
  };

  const handleDeleteSupportPartner = (chipToDelete) => () => {
    dispatch(removeParameter({ materials: { supportPartner: [chipToDelete] } }));
  };

  const handleClearSupportPartner = () => {
    dispatch(clearParameters({ materials: 'supportPartner' }));
  };

  const handleExportToCSV = (materials) => {
    dispatch(exportMaterialsToCSV(materials))
  };

  const handleExportContractsToCSV = (materials) => {
    dispatch(exportMaterialContractsToCSV(materials))
  };

  const materials = useSelector(state => {

    if (state.filteredMaterials) {
      return state.filteredMaterials.map((serialNumber) => {
        return {
          modification_date: serialNumber.modification_date,
          part_number: serialNumber.part_number,
          inactive_reason: serialNumber.inactive_reason,
          status: serialNumber.status,
          creation_note: serialNumber.creation_note,
          comments: serialNumber.comments,
          serial_number: serialNumber.serial_number,
          sn_type: serialNumber.sn_type,
          description: serialNumber.description,
          source_time: serialNumber.source_time,
          type: serialNumber.type,
          creation_date: serialNumber.creation_date,
          sold_to: serialNumber?.sold_to?.applicant_id,
          order_line: serialNumber?.order?.order_line,
          order_id: serialNumber?.order?.order_id,
          purchase_order: serialNumber?.order?.purchase_order,
          end_user_source: serialNumber?.end_user?.source,
          end_user_company_id: serialNumber?.end_user?.company_id,
          business_partner_source: serialNumber?.business_partner?.source,
          business_partner_company_id: serialNumber?.business_partner?.company_id,
          support_partner_source: serialNumber?.support_partner?.source,
          support_partner_company_id: serialNumber?.support_partner?.company_id,
          children: serialNumber?.children,
          parent: serialNumber?.parent
        }
      })
    }
    else return []
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(document.getElementById("menuAnchor"));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterDescription = 'Add filters to the list of results, and export the filtered list to CSV';

  const [sortDirection, setSortDirection] = React.useState('');
  const [sortParameter, setSortParameter] = React.useState('');

  const handleSortMaterials = (sortParameter) => {
    setSortParameter(sortParameter);
    if (sortDirection === '') {
      setSortDirection('ascending');
      dispatch(sortMaterials(sortParameter, 'ascending'));
    } else if (sortDirection === 'ascending') {
      setSortDirection('descending');
      dispatch(sortMaterials(sortParameter, 'descending'));
    } else {
      setSortDirection('ascending');
      dispatch(sortMaterials(sortParameter, 'ascending'));
    };
  };

  const [openBulkConfirmation, setOpenBulkConfirmation] = React.useState(false);
  const [expanded, setExpanded] = React.useState(null);

  const handleKeydown = (event) => {
    if (event.key == 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      setTimeout(() => {
        handleExecuteSearch();
      }, 0);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <React.Fragment>
      <Modal
        open={openBulkConfirmation}
        style={{
          textAlign: "center",
          paddingTop: "45vh"
        }}>
        <>
          <Typography
            style={{ color: 'white' }}
          >
            This query could take a few minutes, are you sure you want to continue?
          </Typography>
          <div style={{ paddingBottom: '1%' }} />
          <Grid container className='headercontainer'>
            <Grid item>
              <Button
                style={{
                  color: 'white',
                  background: '#6b469e'
                }}
                onClick={() => { handleExecuteBulkSearch(); setOpenBulkConfirmation(false) }}
              >Yes</Button>
            </Grid>
            <Grid item style={{ width: '7%' }} />
            <Grid item>
              <Button
                style={{
                  color: 'white',
                  background: '#6b469e'
                }}
                onClick={() => setOpenBulkConfirmation(false)}
              >No</Button>
            </Grid>
          </Grid>
        </>
      </Modal>
      <MultiValueInput
        setCurrentParam={setCurrentSerialNumber}
        setQueryParams={setParameters}
        currentParam={currentSerialNumber}
        queryParams={queryParameters?.serialNumbers}
        handleDelete={handleDeleteSerialNumber}
        handleClear={handleClearSerialNumbers}
        label="Serial Number"
        family='materials'
        type='serialNumbers'
      />
      <MultiValueInput
        setCurrentParam={setCurrentBusinessPartner}
        setQueryParams={setParameters}
        currentParam={currentBusinessPartner}
        queryParams={queryParameters?.businessPartners}
        handleDelete={handleDeleteBusinessPartner}
        handleClear={handleClearBusinessPartners}
        label="Business Partner"
        family='materials'
        type='businessPartners'
      />
      <MultiValueInput
        setCurrentParam={setCurrentSoldTo}
        setQueryParams={setParameters}
        currentParam={currentSoldTo}
        queryParams={queryParameters?.soldTos}
        handleDelete={handleDeleteSoldTo}
        handleClear={handleClearSoldTo}
        label="Sold To"
        family='materials'
        type='soldTos'
      />
      <MultiValueInput
        setCurrentParam={setCurrentOrder}
        setQueryParams={setParameters}
        currentParam={currentOrder}
        queryParams={queryParameters?.order}
        handleDelete={handleDeleteOrder}
        handleClear={handleClearOrder}
        label="SAP Order"
        family='materials'
        type='order'
      />
      <MultiValueInput
        setCurrentParam={setCurrentEndUser}
        setQueryParams={setParameters}
        currentParam={currentEndUser}
        queryParams={queryParameters?.endUser}
        handleDelete={handleDeleteEndUser}
        handleClear={handleClearEndUser}
        label="End Customer ID"
        family='materials'
        type='endUser'
      />
      <MultiValueInput
        setCurrentParam={setCurrentSupportPartner}
        setQueryParams={setParameters}
        currentParam={currentSupportPartner}
        queryParams={queryParameters?.supportPartner}
        handleDelete={handleDeleteSupportPartner}
        handleClear={handleClearSupportPartner}
        label="Support Partner"
        family='materials'
        type='supportPartner'
      />
      <MultiValueInputWithWarning
        setCurrentParam={setCurrentBundleReference}
        setQueryParams={setParameters}
        currentParam={currentBundleReference}
        queryParams={queryParameters?.bundleReference}
        handleDelete={handleDeleteBundleReference}
        handleClear={handleClearBundleReference}
        warningText='Warning: Query can be large, results may be incomplete'
        label="Bundle Reference"
        family='materials'
        type='bundleReference'
      />
      <MultiValueInputWithWarning
        setCurrentParam={setCurrentContractReferences}
        setQueryParams={setParameters}
        currentParam={currentContractReferences}
        queryParams={queryParameters?.contractReferences}
        handleDelete={handleDeleteContractReference}
        handleClear={handleClearContractReference}
        warningText='Warning: Query can be large, results may be incomplete'
        label="Contract Reference"
        family='materials'
        type='contractReferences'
      />
      <Grid>
        <SearchDateInput
          title={'Creation Date'}
          setFilterDateStart={setParameters}
          setFilterDateEnd={setParameters}
          family='materials'
          type='creationDateStart'
          type2='creationDateEnd'
        />
      </Grid>
      <Grid
        container
        style={{
          justifyContent: 'center'
        }}
      >
        <Grid item>
          <Button variant="contained" onClick={handleExecuteSearch} style={{ width: '15vw' }}>Search</Button>
        </Grid>
        <div style={{ paddingLeft: '0.2%' }} />
        <Grid item>
          <Button variant="contained" onClick={() => setOpenBulkConfirmation(true)} style={{ width: '15vw' }}>Bulk Export</Button>
        </Grid>
      </Grid>
      <Title>Materials</Title>
      <Typography>
        {materials?.length ?
          materials?.length === 100 ?
            materials?.length + ' results, use bulk export for more' :
            materials?.length + ' results' :
          null
        }
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Serial Number"
                    variant='standard'
                    onChange={(e) => { setFilterSerialNumbers(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortMaterials('serial_number')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'serial_number' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'serial_number' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  Creation Date
                </Grid>
                <Grid
                  item
                  onClick={() => handleSortMaterials('creation_date')}
                >
                  {sortDirection === 'ascending' && sortParameter === 'creation_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'creation_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  Modification Date
                </Grid>
                <Grid
                  item
                  onClick={() => handleSortMaterials('modification_date')}
                >
                  {sortDirection === 'ascending' && sortParameter === 'modification_date' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'modification_date' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Part Number"
                    variant='standard'
                    onChange={(e) => { setFilterPartNumbers(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortMaterials('part_number')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'part_number' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'part_number' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Sold To"
                    variant='standard'
                    onChange={(e) => { setFilterSoldTos(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortMaterials('sold_to.applicant_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'sold_to.applicant_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'sold_to.applicant_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Business Partner"
                    variant='standard'
                    onChange={(e) => { setFilterBusinessPartners(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortMaterials('business_partner.company_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'business_partner.company_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'business_partner.company_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
            <TableCell>
              <Grid container className='headercontainer'>
                <Grid item>
                  <TextField
                    label="Order ID"
                    variant='standard'
                    onChange={(e) => { setfilterOrderIds(e.target.value) }}
                    onKeyDown={(e) => { e.stopPropagation(); }}
                  />
                </Grid>
                <Grid item
                  onClick={() => handleSortMaterials('order.order_id')}
                  style={{ alignSelf: 'center' }}
                >
                  {sortDirection === 'ascending' && sortParameter === 'order.order_id' ? <KeyboardArrowDownIcon className='sortbutton' /> :
                    sortDirection === 'descending' && sortParameter === 'order.order_id' ? <KeyboardArrowUpIcon className='sortbutton' /> :
                      <UnfoldMoreIcon className='sortbutton' />
                  }
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materials.map((row, i) => (
            <React.Fragment
              key={row?.serial_number + i}
            >
              <TableRow
                hover
                onClick={() => { handleSelectMaterial(row?.serial_number, row?.order_id, row?.order_line) }}
                style={{
                  cursor: 'pointer'
                }}>
                <TableCell>
                  <Tooltip title={row?.children ? 'Show/hide children' : ''} placement="top">
                    <span>
                      <IconButton
                        style={{
                          // display: row?.children ? 'block' : 'none',
                          opacity: row?.children ? 1 : 0
                        }}
                        disabled={row?.children ? false : true}
                        onClick={(e) => {
                          if (expanded === row?.serial_number) {
                            setExpanded()
                          } else {
                            setExpanded(row?.serial_number)
                          }
                          e.stopPropagation()
                        }}
                      >
                        {expanded === row?.serial_number && <KeyboardArrowDownIcon />}
                        {expanded !== row?.serial_number && <KeyboardArrowRightIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={row?.parent ? 'Show/hide parent' : ''} placement="top">
                    <span>
                      <IconButton
                        style={{
                          // display: row?.children ? 'block' : 'none',
                          opacity: row?.parent ? 1 : 0
                        }}
                        disabled={row?.parent ? false : true}
                        onClick={(e) => {
                          if (expanded === row?.serial_number) {
                            setExpanded()
                          } else {
                            setExpanded(row?.serial_number)
                          }
                          e.stopPropagation()
                        }}
                      >
                        {expanded === row?.serial_number && <KeyboardArrowDownIcon />}
                        {expanded !== row?.serial_number && <KeyboardArrowRightIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                  {row?.serial_number}
                </TableCell>
                <TableCell>{row?.creation_date}</TableCell>
                <TableCell>{row?.modification_date}</TableCell>
                <TableCell>{row?.part_number}</TableCell>
                <TableCell>{row?.sold_to}</TableCell>
                <TableCell>{row?.business_partner_company_id}</TableCell>
                <TableCell>{row?.order_id}</TableCell>
              </TableRow>
              <Accordion
                expanded={expanded === row?.serial_number && row?.children}
              >
                <AccordionSummary style={{ display: 'none' }} />
                <AccordionDetails>
                  <MaterialChildren material={row} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === row?.serial_number && row?.parent}
              >
                <AccordionSummary style={{ display: 'none' }} />
                <AccordionDetails>
                  <MaterialParents material={row} />
                </AccordionDetails>
              </Accordion>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      <Tooltip
        title={<Typography fontSize={17}>{filterDescription}</Typography>}
      >
        <Fab
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            backgroundColor: "#6b469e",
            color: "white",
            height: "5em",
            width: "5em"
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <div
            id="menuAnchor"
            style={{
              position: 'relative',
              bottom: 500,
              right: 250
            }}
          >
          </div>
          <FileDownloadIcon fontSize="large" style={{ height: "70%", width: "70%" }} />
        </Fab>
      </Tooltip>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem >
            <TextField
              label="Serial Number"
              onChange={(e) => { setFilterSerialNumbers(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <Grid>
              <div>
                Creation Date
              </div>
              <DateInput
                setFilterDateStart={setFilterCreationDateStart}
                setFilterDateEnd={setFilterCreationDateEnd}
              />
            </Grid>
          </MenuItem>
          <MenuItem >
            <Grid>
              <div>
                Modification Date
              </div>
              <DateInput
                setFilterDateStart={setFilterModificationDateStart}
                setFilterDateEnd={setFilterModificationDateEnd}
              />
            </Grid>
          </MenuItem>
          <MenuItem >
            <TextField
              label="Part Number"
              onChange={(e) => { setFilterPartNumbers(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <TextField
              label="Sold To"
              onChange={(e) => { setFilterSoldTos(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem >
            <TextField
              label="Business Partner"
              onChange={(e) => { setFilterBusinessPartners(e.target.value) }}
              onKeyDown={(e) => { e.stopPropagation(); }}
            />
          </MenuItem>
          <MenuItem hover="false">
            <Button variant="contained" onClick={() => { handleExportToCSV(materials) }}>Export materials</Button>
          </MenuItem>
          <MenuItem>
            <Button variant="contained" onClick={() => { handleExportContractsToCSV(materials) }}>Export contracts</Button>
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment >
  );
}
