import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import Login from './login';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Modal, CircularProgress, Typography, Button } from '@mui/material';
import Dashboard from './dashboard/Dashboard';
import { setUserSession } from './redux/global/actions';
import Header from '@datalake/towercontrol-banner';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#6b469e',
    },
    secondary: {
      main: '#f50057',
    },
  },
});



function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}


function App() {

  const headerRef = useRef();
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.errors);
  const userSession = useSelector((state) => state.userSession)
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setUserSession(null))
  };

  useEffect(() => {
    const errorCookie = getCookie('error');
    console.log('errorCookie', errorCookie)
    if (errorCookie === 'timeout') {
      setErrorModalOpen(true);
    } else if (errorCookie === 'token') {
      handleLogout();
    }
  });

  return (
    <div className="App">

      <ThemeProvider theme={theme}>
        <Modal
          open={loading}
          style={{
            textAlign: "center",
            paddingTop: "45vh"
          }}
        >
          <CircularProgress />
        </Modal>
        <Modal
          open={errorModalOpen}
          onClose={handleCloseErrorModal}
          style={{ textAlign: "center", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '400px',
            maxWidth: '80%'
          }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#6b469e' }}>
              Oops! Something went wrong.
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px' }}>
              Your most recent query failed, but the result may be cached.
              Please try again in a minute.
            </Typography>
          </div>
        </Modal>

        <Header ref={headerRef} />
        <header className="App-header" style={{ alignItems: 'stretch' }}>
          {userSession ? <Dashboard headerRef={headerRef} /> : <Login />}
        </header>
      </ThemeProvider>
    </div>
  );
};

export default App;
