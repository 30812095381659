import {
    GET_SYSTEM_APPLICATIONS,
    SORT_SYSTEM_APPLICATIONS,
    FILTER_SYSTEM_APPLICATIONS,
    GET_SYSTEM_APPLICATION_DETAILS
} from '../actions';
import {LOADING} from '../../global/actions'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Papa from 'papaparse';

// TODO GLOBAL
const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
        hwsSnsTrail: {}
    },
};

// TODO GLOBAL
function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

// TODO GLOBAL
function downloadBulkResult(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

// TODO GLOBAL
const getNestedValueFromString = (from, ...selectors) =>
    [...selectors].map(s =>
        s
            .replace(/\[([^\[\]]*)\]/g, '.$1.')
            .split('.')
            .filter(t => t !== '')
            .reduce((prev, cur) => prev && prev[cur], from)
    );

function applicationReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    switch (action.type){
        case LOADING:
            break;
        default:
            state.loading = false;
            break;
    };
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;
    switch (action.type) {
        case GET_SYSTEM_APPLICATIONS:
            state = Object.assign({}, state, { systemApplications: action.results, filteredSystemApplications: action.results });
            return state;
        case FILTER_SYSTEM_APPLICATIONS:
            if (!state.systemApplications) {
                state.systemApplications = [];
            };
            let filterSystemApplicationParams = action.data;
            let filteredSystemApplications = [];

            let newSystemApplications = state.systemApplications.filter(m => {
                if (filterSystemApplicationParams?.applicationIds?.length > 0) {
                    for (let applicationId of filterSystemApplicationParams?.applicationIds) {
                        if (!m?.application_id.startsWith(applicationId)) {
                            return false;
                        };
                    };
                };
                if (filterSystemApplicationParams?.businessPartners?.length > 0) {
                    for (let businessPartner of filterSystemApplicationParams?.businessPartners) {
                        if (!m?.business_partner?.company_id.startsWith(businessPartner)) {
                            return false;
                        };
                    };
                };
                if (filterSystemApplicationParams?.soldTos?.length > 0) {
                    for (let soldTos of filterSystemApplicationParams?.soldTos) {
                        if (!m?.sold_to?.applicant_id.startsWith(soldTos)) {
                            return false;
                        };
                    };
                };
                if (filterSystemApplicationParams?.orderIds?.length > 0) {
                    for (let orderId of filterSystemApplicationParams?.orderIds) {
                        if (!m?.order?.order_id.startsWith(orderId)) {
                            return false;
                        };
                    };
                };
                // if (filterSystemParams?.endUsers?.length > 0) {
                //     for (let endUser of filterSystemParams?.endUsers) {
                //         if (!m?.end_user?.company_id.startsWith(endUser)) {
                //             return false;
                //         };
                //     };
                // };
                if (filterSystemApplicationParams?.filterCreationDateStart) {
                    if (!moment(filterSystemApplicationParams.filterCreationDateStart).isBefore(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterSystemApplicationParams?.filterCreationDateEnd) {
                    if (!moment(filterSystemApplicationParams.filterCreationDateEnd).isAfter(moment(m.creation_date))) {
                        return false
                    }
                };
                if (filterSystemApplicationParams?.filterModificationDateStart) {
                    if (!moment(filterSystemApplicationParams.filterModificationDateStart).isBefore(moment(m.modification_date))) {
                        return false
                    }
                };
                if (filterSystemApplicationParams?.filterModificationDateEnd) {
                    if (!moment(filterSystemApplicationParams.filterModificationDateEnd).isAfter(moment(m.modification_date))) {
                        return false
                    }
                };
                return true
            });
            filteredSystemApplications = filteredSystemApplications.concat(newSystemApplications)

            state = Object.assign({}, state, { filteredSystemApplications });
            return state;

        case SORT_SYSTEM_APPLICATIONS:
            console.log("SORT_SYSTEM_APPLICATIONS", action.data);
            const systemApplicationParam = action.data.sortParameter;
            const systemApplicationDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredSystemApplications?.sort(function (a, b) {
                var textA = getNestedValueFromString(a, systemApplicationParam)?.toString()?.toUpperCase();
                var textB = getNestedValueFromString(b, systemApplicationParam)?.toString()?.toUpperCase();
                if (systemApplicationDirection === 'ascending') {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                } else {
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                };
            });
            return state;
        case GET_SYSTEM_APPLICATION_DETAILS:
            if (!state.systemApplications) {
                state.systemApplications = [];
            };
            let systemApplication;

            if (action?.results && action.results?.length) {
                systemApplication = action.results[0];
                if (systemApplication?.entitlements) {
                    const contractsSet = state.contracts instanceof Set ? state.contracts : new Set(state.contracts);
                    for (let contract of systemApplication.entitlements) {
                        let contractIdentifier = contract.contract_reference + contract.type
                        contract.contract_identifier = contractIdentifier;
                        contractsSet.add(contract);
                    }
                    state.contracts = Array.from(contractsSet);
                }
                state.systemApplications = state.systemApplications.filter(s => s.applicant_id !== systemApplication.applicant_id);
                state.systemApplications.push(systemApplication);
            };
            state = Object.assign({}, state, { loading: false });
            return state;
        default:
            return state;
    }
    return state;
}

export default applicationReducer