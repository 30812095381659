import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import AlertModal from '../widgets/alertModal';
import Asset from './tables/asset';
import Client from './tables/client';
import Order from './tables/order';
import { getContractDetails } from '../redux/contract/actions';
import Contracts from './tables/contracts';
import { extractAndDelete, formatDetailsKey } from '../utils';
import Extra from './tables/extra';


export default function LicenseDetails() {

    const location = useLocation();
    let licenseId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    const license = useSelector((state) => {
        let _license = state?.licenses?.find(m => m.license_id === licenseId);
        if (_license) {
            return JSON.parse(JSON.stringify(_license));
        } else return {}
    });

    const licenseClone = JSON.parse(JSON.stringify(license));

    const assetFields = [
        { name: 'License ID', key: 'license_id' },
        { name: 'Creation Date', key: 'creation_date' },
        { name: 'Description', key: 'description' },
        { name: 'Inactive Reason', key: 'inactive_reason' },
        { name: 'Modification Date', key: 'modification_date' },
        { name: 'Part Number', key: 'part_number' },
        { name: 'Source Time', key: 'source_time' },
        { name: 'Status', key: 'status' },
    ];

    const assetDetails = assetFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(licenseClone, key) || ''
    }));

    const clientDetails = [
        { name: 'Support Partner', value: licenseClone?.support_partner?.company_id, hover: licenseClone?.support_partner?.source },
        { name: 'End User', value: licenseClone?.end_user?.company_id, hover: licenseClone?.end_user?.source },
        { name: 'Sold To', value: licenseClone?.sold_to?.applicant_id },
        { name: 'Business Partner', value: licenseClone?.business_partner?.company_id, hover: licenseClone?.business_partner?.source },
    ];

    delete licenseClone.support_partner;
    delete licenseClone.end_user;
    delete licenseClone.sold_to;
    delete licenseClone.business_partner;

    const order = license?.order;

    const orderDetails = [
        { name: 'Order ID', value: order?.order_id },
        { name: 'Order Line', value: order?.order_line },
        { name: 'Order Line Type', value: order?.order_line_type },
        { name: 'Order Line Type ID', value: order?.order_line_type_id },
        { name: 'Parent Order Line', value: order?.parent_order_line },
        { name: 'Part Description', value: order?.part_description },
        { name: 'Part Number', value: order?.part_number },
        { name: 'Quantity', value: order?.quantity },
        { name: 'Sold To', value: order?.sold_to },
        { name: 'Purchase Order', value: order?.purchase_order }
    ];

    delete licenseClone.order;

    const extraDetails = Object.entries(licenseClone).flatMap(([key, value]) => {
        if (key.toLocaleLowerCase() !== 'entitlements') {
            if (value && typeof value === 'object') {
                return Object.entries(value).map(([subKey, subValue]) => ({         // max 1 depth
                    name: `${formatDetailsKey(key)} - ${formatDetailsKey(subKey)}`,
                    value: subValue || ''
                }));
            } else {
                return [{
                    name: formatDetailsKey(key),
                    value: value || ''
                }];
            }
        }
    });

    return (<>
        <AlertModal />
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '1%'
            }}
        >
            <Asset assetDetails={assetDetails} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Client clientDetails={clientDetails} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Order orderDetails={orderDetails} />
            <div style={{ marginBottom: '1%' }} />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Extra extraDetails={extraDetails} />
        </div>
        <Contracts getContractDetails={getContractDetails} />
    </>)
}
