import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import { useDispatch } from 'react-redux';

import Customer from './tables/customer';
import System from './tables/system';
import Contracts from './tables/contracts';
import Extra from './tables/extra';
import { getSystemDetails } from '../redux/system/actions';
import { getContractDetails } from '../redux/contract/actions';
import { extractAndDelete, formatDetailsKey } from '../utils';

export default function SystemDetails() {
    const dispatch = useDispatch();
    const location = useLocation();

    let systemId = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    const system = useSelector((state) => {
        let _system = state?.systems?.find(s => s.system_id === systemId);
        if (_system) {
            return JSON.parse(JSON.stringify(_system));
        } else return {};
    });

    React.useEffect(() => {
        if (!system && !loading && !errors) {
            dispatch(getSystemDetails(systemId));
        }
    }, [dispatch, system, systemId, loading, errors]);

    const systemClone = JSON.parse(JSON.stringify(system));

    const systemFields = [
        { name: 'System ID', key: 'system_id' },
        { name: 'Name', key: 'name' },
        { name: 'Description', key: 'description' },
        { name: 'Status', key: 'status' },
        { name: 'Order ID', key: 'order.order_id' },
        { name: 'Purchase Order', key: 'order.purchase_order' },
        { name: 'Part Number', key: 'part_number' },
        { name: 'CPU ID 1', key: 'cpu_id1' },
        { name: 'CPU ID 2', key: 'cpu_id2' },
        { name: 'Product', key: 'product' },
        { name: 'Release', key: 'release' },
        { name: 'Inactive Reason', key: 'inactive_reason' },
        { name: 'Creation Date', key: 'creation_date' },
        { name: 'Creation Note', key: 'creation_note' },
        { name: 'Modification Date', key: 'modification_date' }
    ];

    const systemDetails = systemFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(systemClone, key) || ''
    }));

    const customerFields = [
        { name: 'Business Partner', key: 'business_partner.company_id' },
        { name: 'Support Partner', key: 'support_partner.company_id' },
        { name: 'Sold To', key: 'sold_to.applicant_id' }
    ];

    const customerDetails = customerFields.map(({ name, key }) => ({
        name,
        value: extractAndDelete(systemClone, key) || ''
    }));

    const extraDetails = Object.entries(systemClone).flatMap(([key, value]) => {
        if (key.toLocaleLowerCase() !== 'entitlements') {
            if (value && typeof value === 'object') {
                return Object.entries(value).map(([subKey, subValue]) => ({         // max 1 depth
                    name: `${formatDetailsKey(key)} - ${formatDetailsKey(subKey)}`,
                    value: subValue || ''
                }));
            } else {
                return [{
                    name: formatDetailsKey(key),
                    value: value || ''
                }];
            }
        }
    });

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <System systemDetails={systemDetails} />
                <div style={{ marginLeft: '1%' }} />
                <Customer customerDetails={customerDetails} />
                <div style={{ marginLeft: '1%' }} />
                <Extra extraDetails={extraDetails} />
            </div>
            <div style={{ marginBottom: '1%' }} />
            <Contracts getContractDetails={getContractDetails} />
        </>
    );
}

