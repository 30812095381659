import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import {
    useNavigate,
} from "react-router-dom";
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { getChild, getMaterialDetails } from '../../redux/material/actions';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Asset(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const assetDetails = props?.assetDetails;

    function selectParent(parent) {
        dispatch(getMaterialDetails(parent.serial_number, parent?.order?.order_id, parent?.order?.order_line));
        navigate('/materials/details/' + parent.serial_number);
    };

    function selectChild(child) {
        dispatch(getChild({ serial_number: child }));
        navigate('/materials/details/' + child);
    };

    const parent = props?.parent;
    const children = props?.children;

    return (
        < React.Fragment >
            <div style={{ width: '100%' }}>

                <TableContainer component={Paper} >
                    <Accordion
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            style={{
                                backgroundColor: "#6b469e",
                                color: "white",
                                height: '3vh'
                            }}
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: 0
                                },
                                "&.MuiAccordionSummary-root": {
                                    minHeight: 0
                                },
                            }}
                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        >
                            <Typography
                                style={{
                                    width: '100%'
                                }}
                            >
                                <WebAssetIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Asset (Source: Datalake)
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Table sx={{ minWidth: '30%' }} size="small" aria-label="client table">
                                <TableBody>
                                    {assetDetails.map((row, i) => {
                                        if (row?.value) {
                                            return (
                                                <TableRow>
                                                    <TableCell key={row.name + i + " namecell"}>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell key={row.value + i + " valuecell"}>
                                                        {row.value}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                    {parent ? <TableRow
                                        hover
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => { selectParent(parent) }}
                                    >
                                        <TableCell>
                                            Parent
                                        </TableCell>
                                        <TableCell>
                                            {parent?.serial_number}
                                        </TableCell>
                                    </TableRow> : null}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>

                </TableContainer>
                <TableContainer>
                    {children ? <Table sx={{ minWidth: '30%' }} size="small" aria-label="client table">
                        <TableRow>
                            <TableCell
                                style={{
                                    backgroundColor: "#6b469e",
                                    color: "white",
                                }}
                                colSpan="100%"
                            >
                                <WebAssetIcon style={{ marginRight: '1%', verticalAlign: "middle" }} />
                                Children
                            </TableCell>
                        </TableRow>
                        {children.map((child, i) => {
                            return (
                                <TableRow
                                    hover
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => { selectChild(child) }}
                                >
                                    <TableCell>{child}</TableCell>
                                </TableRow>
                            )
                        })}
                    </Table> : null}
                </TableContainer>
            </div>
        </React.Fragment >
    );
}